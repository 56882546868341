<template>
  <div class="main">
    <div class="name">
      <b>{{ name }}</b>
    </div>

    <div class="time-div">
      <div class="time" v-for="t in chunkedInterval" :key="t.id">
        <b-field class="time-interval" grouped group-multiline>
          <b-field grouped group-multiline>
            <b-timepicker class="timepicker" v-model="t[0].time_from" size="is-small" inline :disabled="isDisabled" />
            <div style="display: table">
              <div style="display: table-cell; vertical-align: middle;">
                <div>
                  <p>  até  </p>
                </div>
              </div>
            </div>
            <b-timepicker class="timepicker" v-model="t[0].time_to" size="is-small" inline :disabled="isDisabled" />
            <b-button v-if="!isDisabled && openAtTime.length > 1" icon-right="close" v-on:click="removeInterval(t[0].id)" />
          </b-field>
          <b-field v-if="t.length === 2" grouped group-multiline>
            <b-timepicker class="timepicker" v-model="t[1].time_from" size="is-small" inline :disabled="isDisabled" />
            <div style="display: table">
              <div style="display: table-cell; vertical-align: middle;">
                <div>
                  <p>  até  </p>
                </div>
              </div>
            </div>
            <b-timepicker class="timepicker" v-model="t[1].time_to" size="is-small" inline :disabled="isDisabled" />
            <b-button v-if="!isDisabled && openAtTime.length > 1" icon-right="close" v-on:click="removeInterval(t[1].id)" />
          </b-field>
        </b-field>
      </div>
      <div class="add-time">
        <b-button v-if="!isDisabled" icon-left="plus-thick" v-on:click="addInterval">Adicionar</b-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OpenAtTableRow',
  props: {
    name: {
      type: String,
      required: true
    },
    openAtTime: {
      type: Array,
      required: true
    },
    isDisabled: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      open_at_time: [],
      tdClass: 'td-middle'
    }
  },
  computed: {
    chunkedInterval () {
      const result = []
      for (let i = 0; i < this.openAtTime.length; i += 2) {
        result.push(this.openAtTime.slice(i, i + 2))
      }
      return result
    }
  },
  created () {
    // this.openAtTime = JSON.parse(JSON.stringify(this.openAtTime))
    this.openAtTime.forEach(t => {
      t.time_from = new Date(t.time_from)
      t.time_to = new Date(t.time_to)
    })
  },
  methods: {
    addInterval () {
      this.$emit('addInterval', { day: this.name })
    },
    removeInterval (id) {
      this.$emit('removeInterval', { id: id, day: this.name })
    }
  },
  watch: {
    open_at_time (newArray, oldArray) {
      if (newArray.length > 2) {
        this.tdClass = 'td-top'
      }
      this.$emit('addInterval', { time: this.open_at_time, day: this.name })
    }
  }
}
</script>

<style scoped>

.main {
  margin-bottom: 50px;
}

.name {
  margin-bottom: 15px;
}

.time {
  margin-right: 20px;
  height: 65px;
}

.time .timepicker {
  margin: 5px !important;
}

.time p {
  color: #252252;
  margin-right: 5px;
  margin-left: 5px;
}

.time button{
  color: #dd1212;
  border: 0px;
  background-color: transparent;
  position: relative;
  top: 20%;
  left: -3%;
}

.add-time {
  display: inline-block;
  height: 40px;
}

.add-time button{
  color: #00d1b2;
  border: 0px;
  background-color: transparent;
  margin-left: -5px;
}

</style>
