<template>
  <div>
    <button
        class="button is-info is-outlined"
        expanded
        type="button"
        @click="updateBusinessHours"
    >
        Horário
    </button>
  </div>
</template>

<script>
import mixin from '../../utils/mixins'

export default {
  mixins: [mixin],
  methods: {
    async updateBusinessHours () {
      this.$emit('updateBusinessHours')
    }
  }
}
</script>

<style scoped>
.w-100 {
  width: 100%;
}
</style>
