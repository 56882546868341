<template>
  <div :class="{ 'close': !isOpen, 'open': isOpen }" @click="selectDay()">{{ title }}</div>
</template>

<script>
export default {
  name: 'DaySelect',
  props: {
    title: {
      type: String,
      required: true
    },
    open: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      isOpen: false
    }
  },
  methods: {
    selectDay () {
      this.isOpen = !this.isOpen
      this.$emit('openDay', { day: this.getDay(this.title) })
    },
    getDay (name) {
      switch (name) {
        case 'Dom':
          return 'SUN'
        case 'Seg':
          return 'MON'
        case 'Ter':
          return 'TUE'
        case 'Qua':
          return 'WED'
        case 'Qui':
          return 'THU'
        case 'Sex':
          return 'FRI'
        case 'Sab':
          return 'SAT'
      }
    }
  },
  created () {
    this.isOpen = this.open
  }
}
</script>

<style lang="scss" scoped>

div {
  display: inline;
  margin: 5px;
  padding: 2px 6px;
  border: 0px;
  border-radius: 5px;
  color: white;
  cursor: pointer;
}

div.close {
  background: #b5b5b5;
}

div.open:hover {
  background: #48c78e96;
}

div.open {
  background: #48C78E;
}

div.close:hover {
  background: #b5b5b596;
}

</style>
