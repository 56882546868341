<template>
  <div>
    <div v-if="allBusinessHours.length > 0">
      <b-table
        :data="allBusinessHours"
        :bordered="false"
        :striped="true"
        :narrowed="false"
        :hoverable="true"
        :loading="false"
        :focusable="false"
        :mobile-cards="true"
        :paginated="true"
        scrollable
      >
        <b-table-column :numeric="true" field="id" label="ID" v-slot="props">
          {{ props.row.id }}
        </b-table-column>

        <b-table-column field="description" label="Descrição" v-slot="props">
          {{ props.row.description }}
        </b-table-column>

        <b-table-column field="status" label="Status" v-slot="props">
          <IsActiveTag :active="props.row.enabled" />
        </b-table-column>

        <b-table-column field="update" v-slot="props">
          <BusinessHoursButton @updateBusinessHours="updateBusinessHours(props.row)" />
        </b-table-column>

        <b-table-column v-if="canUpdate" v-slot="props">
          <b-button type="is-danger" outlined @click="deleteBusinessHours(props.row)">Excluir</b-button>
        </b-table-column>

        <template #footer>
          <div class="has-text-left">
            Quantidade de registros {{ allBusinessHours.length }}
          </div>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import mixin from '../../utils/mixins'
import BusinessHoursButton from '@/components/businessHours/BusinessHoursButton'
import IsActiveTag from '../commons/IsActiveTag.vue'

export default {
  name: 'BusinessHoursList',
  props: {
    businessHours: {
      type: Array,
      required: true
    },
    canUpdate: {
      type: Boolean,
      required: true
    }
  },
  mixins: [mixin],
  components: {
    BusinessHoursButton,
    IsActiveTag
  },
  computed: {
    allBusinessHours: {
      get () {
        return this.businessHours
      },
      set () {
        // do nothing
      }
    }
  },
  methods: {
    updateBusinessHours (businessHours) {
      this.$emit('updateBusinessHours', businessHours)
    },
    deleteBusinessHours (businessHours) {
      this.$buefy.dialog.confirm({
        title: 'Excluir horário',
        message: 'Ao excluir o horário ele será removido dos departamentos e números aos quais estiver vinculado. Deseja <b>excluir</b> mesmo assim?',
        confirmText: 'Excluir horário',
        type: 'is-danger',
        size: 'is-big',
        hasIcon: true,
        cancelText: 'Cancelar',
        onConfirm: () => this.$emit('deleteBusinessHours', businessHours.id)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
