<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <hero-bar :has-right-visible="false"> Horário de Funcionamento </hero-bar>
    <section class="section is-main-section">
      <ModalBusinessHours
        v-if="isModalBusinessHoursOpen"
        :businessHours="businessHours"
        :isUpdating="isUpdating"
        v-on:closeModal="closeModal"
        :isComponentModalActive="isModalBusinessHoursOpen"
        v-on:addBusinessHours="handleBusinessHours"
        :canUpdate="getIsAdmin || getIsSupervisorAllDepartments"
        :descriptions="descriptions"
      />
      <b-loading
        v-if="fetchingBusinessHours"
        :is-full-page="true"
        v-model="fetchingBusinessHours"
        :can-cancel="false"
      ></b-loading>
      <BusinessHoursList
        :businessHours="allBusinessHours"
        :canUpdate="getIsAdmin || getIsSupervisorAllDepartments"
        @updateBusinessHours="handleUpdateBusinessHours"
        @deleteBusinessHours="handleDeleteBusinessHours" />
      <FabButton
        v-if="getIsAdmin || getIsSupervisorAllDepartments"
        v-on:addClick="handleAddClick"
        iconClass="fas fa-pen-fancy"
        title="Novo Horário"
      />
    </section>
  </div>
</template>

<script>
import TitleBar from '@/components/TitleBar'
import HeroBar from '@/components/HeroBar'
import FabButton from '@/components/FabButton.vue'
import BusinessHoursList from '@/components/businessHours/BusinessHoursList'
import ModalBusinessHours from '@/components/modals/ModalBusinessHours'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'BusinessHours',
  components: {
    HeroBar,
    TitleBar,
    FabButton,
    BusinessHoursList,
    ModalBusinessHours
  },
  data () {
    return {
      isModalBusinessHoursOpen: false,
      isUpdating: false,
      idUpdating: null,
      businessHours: {},
      descriptions: []
    }
  },
  computed: {
    ...mapGetters(['allBusinessHours', 'fetchingBusinessHours', 'getIsSupervisorAllDepartments', 'getIsAdmin']),
    titleStack () {
      return ['Cadastros', 'Horários de Funcionamento']
    }
  },
  methods: {
    ...mapActions(['addBusinessHours', 'fetchBusinessHours', 'updateBusinessHours', 'deleteBusinessHours']),
    async handleBusinessHours (businessHours) {
      this.closeModal()

      if (this.isUpdating) {
        await this.updateBusinessHours({ body: businessHours, id: this.idUpdating }).catch(() => {
          this.$buefy.dialog.alert({
            title: 'Erro',
            message: 'Não foi possível atualizar o horário, pois o mesmo está vinculado à departamentos, à números ou à mensagens agendadas pendentes.',
            type: 'is-danger',
            hasIcon: true,
            icon: 'times-circle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
        })
      } else {
        await this.addBusinessHours(businessHours).catch((err) => {
          this.$buefy.dialog.alert({
            title: 'Erro',
            message: `Ocorreu um <b>erro</b> ao inserir o horário. Recarregue a tela e tente novamente! <br> ${err.response.data.message}`,
            type: 'is-danger',
            hasIcon: true,
            icon: 'times-circle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
        })
      }
    },
    handleAddClick () {
      this.isUpdating = false
      this.businessHours = {}
      this.isModalBusinessHoursOpen = !this.isModalBusinessHoursOpen
    },
    handleUpdateBusinessHours (businessHours) {
      this.isUpdating = true
      this.idUpdating = businessHours.id
      this.businessHours = JSON.parse(JSON.stringify(businessHours))
      this.isModalBusinessHoursOpen = !this.isModalBusinessHoursOpen
    },
    async handleDeleteBusinessHours (id) {
      try {
        await this.deleteBusinessHours(id)
        this.$buefy.toast.open('Horário excluido com sucesso!')
      } catch (error) {
        this.$buefy.toast.open(
          'Não é possível excluir um horário utilizado em mensagens agendadas!'
        )
      }
    },
    closeModal () {
      this.businessHours = {}
      this.isModalBusinessHoursOpen = false
    },
    setDescriptions () {
      if (this.allBusinessHours) {
        this.descriptions = this.allBusinessHours.map(b => b.description)
      }
    }
  },
  async created () {
    await this.fetchBusinessHours()
    this.setDescriptions()
  }
}
</script>
