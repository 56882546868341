<template>
  <div>
    <section>
      <b-modal :active="isComponentModalActive" @close="closeModal" :width="640">
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">{{ isUpdating ? businessHours.description : 'Inserir Horário de Funcionamento' }}</p>
          </header>
          <section class="modal-card-body">
            <b-message
              class="warning-message"
              type="is-danger"
              v-if="isUpdating && canUpdate"
            >
              A atualização do horário de funcionamento afeta os números, mensagens agendadas e departamentos ligados a ele. Ou seja, eles obedecerão o novo horário definido.
            </b-message>
            <b-field label="Descrição" :message="invalidName.message" :type="invalidName.style">
              <b-input
                type="text"
                v-model="description"
                placeholder="Descrição"
                minlength="3"
                required
                :disabled="!canUpdate"
                @input="verifyName"
              />
            </b-field>
            <b-checkbox v-model="enabled">Horário ativo</b-checkbox>

            <div class="days-open">
              <label><b>Dias abertos </b></label>
              <DaySelect :title="'Dom'" :open="openDays.SUN" @openDay="openDay" />
              <DaySelect :title="'Seg'" :open="openDays.MON" @openDay="openDay" />
              <DaySelect :title="'Ter'" :open="openDays.TUE" @openDay="openDay" />
              <DaySelect :title="'Qua'" :open="openDays.WED" @openDay="openDay" />
              <DaySelect :title="'Qui'" :open="openDays.THU" @openDay="openDay" />
              <DaySelect :title="'Sex'" :open="openDays.FRI" @openDay="openDay" />
              <DaySelect :title="'Sab'" :open="openDays.SAT" @openDay="openDay" />
            </div>

            <br>

            <div class="same-time">
              <b-checkbox v-model="sameTime">Aplicar mesmo horário a todos os dias abertos</b-checkbox>
            </div>

            <hr>

            <div v-if="sameTime">
              <div class="time" v-for="t in chunkedInterval" :key="t.id">
                <b-field class="time-interval" grouped group-multiline>
                  <b-field grouped group-multiline>
                    <b-timepicker class="timepicker" v-model="t[0].time_from" size="is-small" inline :disabled="!canUpdate" />
                    <div style="display: table">
                      <div style="display: table-cell; vertical-align: middle;">
                        <div>
                          <p>  até  </p>
                        </div>
                      </div>
                    </div>
                    <b-timepicker class="timepicker" v-model="t[0].time_to" size="is-small" inline :disabled="!canUpdate" />
                    <b-button v-if="canUpdate && openAtTime.length > 1" icon-right="close" v-on:click="removeInterval(t[0].id)" />
                  </b-field>
                  <b-field v-if="t.length === 2" grouped group-multiline>
                    <b-timepicker class="timepicker" v-model="t[1].time_from" size="is-small" inline :disabled="!canUpdate" />
                    <div style="display: table">
                      <div style="display: table-cell; vertical-align: middle;">
                        <div>
                          <p>  até  </p>
                        </div>
                      </div>
                    </div>
                    <b-timepicker class="timepicker" v-model="t[1].time_to" size="is-small" inline :disabled="!canUpdate" />
                    <b-button v-if="canUpdate && openAtTime.length > 1" icon-right="close" v-on:click="removeInterval(t[1].id)" />
                  </b-field>
                </b-field>
              </div>
              <div class="add-time">
                <b-button v-if="canUpdate" icon-left="plus-thick" v-on:click="addInterval">Adicionar</b-button>
              </div>
            </div>

            <div v-if="!sameTime">
              <OpenAtTableRow
                v-for="d in business_hours" :key="d.id"
                :name="d.name"
                :openAtTime="d.time"
                @addInterval="addInterval"
                @removeInterval="removeInterval"
                :isDisabled="!canUpdate"
              />
            </div>
          </section>
          <footer class="modal-card-foot">
            <button class="button is-pulled-right" type="button" @click="closeModal">Fechar</button>
            <button v-if="canUpdate" class="button is-success is-pulled-right" type="button" @click="handleAdd">{{ isUpdating ? 'Atualizar' : 'Gravar' }}</button>
          </footer>
        </div>
      </b-modal>
    </section>
  </div>
</template>

<script>
import mixin from '../../utils/mixins'
import OpenAtTableRow from '@/components/businessHours/OpenAtTableRow'
import DaySelect from '@/components/businessHours/DaySelect'

export default {
  name: 'ModalBusinessHours',
  props: {
    isComponentModalActive: {
      type: Boolean,
      required: true
    },
    businessHours: {
      type: Object,
      required: true
    },
    isUpdating: {
      type: Boolean,
      required: true
    },
    canUpdate: {
      type: Boolean,
      required: true
    },
    descriptions: {
      type: Array,
      required: true
    }
  },
  mixins: [mixin],
  components: {
    OpenAtTableRow,
    DaySelect
  },
  data () {
    return {
      description: '',
      enabled: true,
      invalidName: {
        message: '',
        style: ''
      },
      days: ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'],
      openDays: {
        SUN: true,
        MON: true,
        TUE: true,
        WED: true,
        THU: true,
        FRI: true,
        SAT: true
      },
      sameTime: true,
      openAtTime: [],
      business_hours: []
    }
  },
  computed: {
    chunkedInterval () {
      const result = []
      for (let i = 0; i < this.openAtTime.length; i += 2) {
        result.push(this.openAtTime.slice(i, i + 2))
      }
      return result
    }
  },
  methods: {
    setBusinessHours () {
      if (this.isUpdating) {
        this.sameTime = this.businessHours.same_time
        this.enabled = this.businessHours.enabled
        this.days.forEach(day => {
          const i = this.getDayId(day)
          const name = this.translateDay(day) // retorna o dia (string) que será exibido na tela
          let time = []
          const openHours = this.businessHours.open_at_day.find(el => el.day === day) // retorna os dados do respectivo dia
          if (typeof openHours !== 'undefined') { // se tem dados do dia
            this.openDays[day] = true
            time = openHours.open_at_time.map(t => {
              return { time_from: new Date(t.time_from), time_to: new Date(t.time_to) }
            })

            time = this.setIntervalIds(time)

            this.business_hours.push({ id: i, name: name, time: time })
          } else {
            this.openDays[day] = false
          }
        })
      } else {
        let i = 0
        this.days.forEach(day => {
          if (this.openDays[day]) {
            const time = new Date(new Date().setHours(0, 0, 0, 0))

            this.business_hours.push({ id: i, name: this.translateDay(day), time: [{ time_from: time, time_to: time }] })
            this.business_hours.map(b => {
              b.time = this.setIntervalIds(b.time)
              return b
            })
            i++
          }
        })
      }

      this.sortBusinessHours()
    },
    translateDay (name) {
      switch (name) {
        case 'SUN':
          return 'Domingo'
        case 'MON':
          return 'Segunda-feira'
        case 'TUE':
          return 'Terça-feira'
        case 'WED':
          return 'Quarta-feira'
        case 'THU':
          return 'Quinta-feira'
        case 'FRI':
          return 'Sexta-feira'
        case 'SAT':
          return 'Sábado'
      }
    },
    getDay (name) {
      switch (name) {
        case 'Domingo':
          return 'SUN'
        case 'Segunda-feira':
          return 'MON'
        case 'Terça-feira':
          return 'TUE'
        case 'Quarta-feira':
          return 'WED'
        case 'Quinta-feira':
          return 'THU'
        case 'Sexta-feira':
          return 'FRI'
        case 'Sábado':
          return 'SAT'
      }
    },
    getDayId (day) {
      switch (day) {
        case 'SUN':
          return 0
        case 'MON':
          return 1
        case 'TUE':
          return 2
        case 'WED':
          return 3
        case 'THU':
          return 4
        case 'FRI':
          return 5
        case 'SAT':
          return 6
      }
    },
    sortBusinessHours () {
      this.business_hours.sort((a, b) => {
        return a.id - b.id
      })
    },
    addInterval (data) {
      const time = new Date(new Date().setHours(0, 0, 0, 0))
      if (this.sameTime) {
        this.openAtTime.push({ time_from: time, time_to: time })
        this.openAtTime = this.setIntervalIds(this.openAtTime)
      } else if (data) {
        this.business_hours.map(d => {
          if (d.name === data.day) {
            d.time.push({ time_from: time, time_to: time })
            d.time = this.setIntervalIds(d.time)
          }

          return d
        })
      }
    },
    removeInterval (data) {
      if (this.sameTime) {
        this.openAtTime = this.openAtTime.filter(t => t.id !== data)
      } else {
        this.business_hours.map(bh => {
          if (bh.name === data.day) {
            bh.time = bh.time.filter(t => t.id !== data.id)
          }
          return bh
        })
      }
    },
    setIntervalIds (data) {
      return data.map((el, index) => ({ ...el, id: index }))
    },
    verifyName () {
      if (this.descriptions.length > 0) {
        const name = this.descriptions.filter(d => d.toLowerCase() === this.description.toLowerCase())
        if (((this.isUpdating && this.description === this.businessHours.description) || name.length === 0)) {
          this.invalidName.message = ''
          this.invalidName.style = ''
        } else {
          this.invalidName.message = 'Descrição já utilizada'
          this.invalidName.style = 'is-danger'
        }
      }
    },
    handleAdd () {
      let open = false
      for (const day in this.openDays) {
        if (this.openDays[day]) {
          open = true
        }
      }

      let noneInterval = false
      const invalidDescription = !this.description || this.description === ''
      let invalidInterval = false
      let duplicateDescription = false

      if (this.descriptions.length > 0) {
        const name = this.descriptions.filter(d => d.toLowerCase() === this.description.toLowerCase())
        if ((this.isUpdating && this.description !== this.businessHours.description && name.length > 0) || (name.length > 0 && !this.isUpdating)) {
          duplicateDescription = true
        }
      }

      if (open) {
        if (this.sameTime) {
          if (this.openAtTime.length === 0) {
            noneInterval = true
          }

          invalidInterval = !this.isValidInterval(this.openAtTime)
        } else {
          for (let i = 0; i < this.business_hours.length; i++) {
            if (this.business_hours[i].time.length === 0) {
              noneInterval = true
              break
            }
            if (!this.isValidInterval(this.business_hours[i].time)) {
              invalidInterval = true
              break
            }
          }
        }
      }

      if (invalidDescription) {
        this.$buefy.dialog.alert({
          title: 'Atenção! Horário inválido',
          message: 'É necessário informar uma descrição!',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })

        return
      }

      if (duplicateDescription) {
        this.$buefy.dialog.alert({
          title: 'Atenção! Horário inválido',
          message: `Já existe cadastro de horário com o nome "${this.description}"!`,
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })

        return
      }

      if (noneInterval) {
        this.$buefy.dialog.alert({
          title: 'Atenção! Horário incompleto',
          message: 'É necesssário inserir pelo menos um intervalo!',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })

        return
      }

      if (invalidInterval) {
        this.$buefy.dialog.alert({
          title: 'Atenção! Horário incompleto',
          message: 'Os horários em um intervalo devem ser diferentes um do outro!',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })

        return
      }

      this.addBusinessHours()
    },
    isValidInterval (intervals) {
      // aplicável para o openAtTime, e para o business_hours[i].time
      let valid = true

      for (let i = 0; i < intervals.length; i++) {
        const timeFrom = intervals[i].time_from.getHours() + ':' + intervals[i].time_from.getMinutes() + ':' + intervals[i].time_from.getSeconds()
        const timeTo = intervals[i].time_to.getHours() + ':' + intervals[i].time_to.getMinutes() + ':' + intervals[i].time_to.getSeconds()
        if (timeFrom === timeTo) {
          valid = false
          break
        }
      }
      return valid
    },
    addBusinessHours () {
      const days = JSON.parse(JSON.stringify(this.business_hours))
      let data
      if (this.sameTime) {
        data = {
          description: this.description ? this.description : '',
          enabled: this.enabled,
          same_time: this.sameTime,
          days: days.map(h => {
            h.time = this.openAtTime
            h.day = this.getDay(h.name)
            return h
          })
        }
      } else {
        data = {
          description: this.description ? this.description : '',
          enabled: this.enabled,
          same_time: this.sameTime,
          days: days.map(el => {
            el.day = this.getDay(el.name)
            return el
          })
        }
      }

      this.$emit('addBusinessHours', data)
      this.business_hours = []
    },
    closeModal () {
      this.business_hours = []
      this.$emit('closeModal')
    },
    openDay (data) {
      this.openDays[data.day] = !this.openDays[data.day]
      if (this.openDays[data.day]) {
        if (this.sameTime && this.openAtTime) {
          this.business_hours.push({ id: this.getDayId(data.day), name: this.translateDay(data.day), time: this.openAtTime })
        } else if (!this.sameTime) {
          const time = new Date(new Date().setHours(0, 0, 0, 0))
          this.business_hours.push({ id: this.getDayId(data.day), name: this.translateDay(data.day), time: [{ id: 0, time_from: time, time_to: time }] })
        }
      } else {
        this.business_hours = this.business_hours.filter(b => this.getDay(b.name) !== data.day)
      }

      this.sortBusinessHours()
    }
  },
  created () {
    this.description = this.businessHours.description
    this.setBusinessHours()
    if (this.isUpdating && this.businessHours.same_time) {
      this.openAtTime = this.business_hours[0].time
    } else {
      this.addInterval(null)
    }
  },
  watch: {
    sameTime: function (newVal) {
      if (newVal && this.openAtTime && this.openAtTime.length === 0) {
        this.addInterval(null)
      } else if (!newVal && this.openAtTime && this.openAtTime.length > 0) {
        for (const day of this.business_hours) {
          day.time = JSON.parse(JSON.stringify(this.openAtTime))
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .modal .animation-content .modal-card {
    overflow: visible !important;
    width: auto;
    // height: 100vh;
  }

  .modal-card-body {
    overflow: visible !important;
    padding-bottom: 40px;
  }

  .modal-card-foot {
    justify-content: flex-end;
  }

  .days-open {
    margin-top: 15px;
  }

  .add-time {
    display: inline-block;
    height: 40px;
  }

  .add-time button{
    color: #00d1b2;
    border: 0px;
    background-color: transparent;
    margin-left: -5px;
  }

  .time {
    margin-right: 20px;
    height: 65px;
  }

  .time .timepicker {
    margin: 5px !important;
  }

  .time p {
    // font-size: 12px;
    color: #252252;
    margin-right: 5px;
    margin-left: 5px;
  }

  .time button{
    color: #dd1212;
    border: 0px;
    background-color: transparent;
    position: relative;
    // margin: -20px;
    top: 20%;
    left: -3%;
  }
</style>
